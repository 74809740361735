<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import axios from "axios";
import Swal from "sweetalert2";
import $ from "jquery";
// import moment from 'moment';
import Cookies from "js-cookie";

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Tambah Permohonan Surat Keluar",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Surat Keluar",
      items: [
        {
          text: "Home",
          href: "/",
        },
        {
          text: "Surat Keluar",
          href: "/surat-keluar",
        },
        {
          text: "Tambah",
          active: true,
        },
      ],
      // variable Page Table
      loadingTable: true,
      dataTable: [],
      pagingTable: [],
      showDataToTable: "",
      showDataFromTable: "",
      totalDataTable: "",
      currentTablePage: "",
      searchDataTable: "",
      departemen: [],
      departemen_selected: "",
      departemen_selected_code: "",
      tipe_surat_keluar: [],
      tipe_surat_keluar_selected: "",
      tipe_surat_keluar_selected_kode: "",
      tipe_surat_keluar_selected_id: "",
      ad_nomorsurat: "",
      bulan_surat: "",
      tahun_surat: "",
      ad_sifatsurat: "",
      sifat_surat_selected: "",
      no_surat: "",
      bentuk_dokumen: [],
      bentuk_dokumen_selected: "",
      kategori_surat: [],
      kategori_surat_selected: "",
      isNoTelp: false,
      isKategoriSurat: false,
      isTeamLeader: false,
      isDivisi: false,
      team_leader: [],
      team_leader_selected: "",
      team_leader_selected_nama: "",

      EditNoSuratModal: false,
      ad_divisi: "",
      draft: "y",
      ad_lampiran: "",
      ad_lampiran_password: "",
      ad_tanggalsuratditerima: "",
      ad_tanggalsurat: "",
      ad_instansipengirim: "",
      ad_pic: "",
      ad_notelp: "",
      ad_tandatangan: "",
      ad_dikirim: "",
      ad_perihal: "",
      loadingNoSurat: true,

      departemen_id: Cookies.get("session_departemen"),
      role_slug: Cookies.get("session_role_slug"),

      showLampiran: false,
      
      tipe: this.$route.params.tipe,
      //lampiran
      lampiran: [],
    };
  },
  mounted() {
    this.getDepartemen();
    this.getTipeSuratKeluar();
    this.getKategoriSurat();

    this.bentuk_dokumen.push(
      {
        key: 'surat',
        value: 'Surat',
      },
      {
        key: 'surat_dan_proposal',
        value: 'Surat dan Proposal',
      },
      {
        key: 'surat_dan_dokumen_pendukung_lainnya',
        value: 'Surat dan Pendukung Lainnya',
      },
    );
  },
  methods: {
    getDepartemen() {
      let self = this;
      axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "api/master/departemen?status=ENABLE&role_slug=" + self.role_slug + '&departement_id=' + self.departemen_id
        )
        .then((response) => {
          var response_data = response.data;
          if (response_data.code == 200) {
            self.departemen = response_data.list_data.data;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data.message,
            });
          }
        });
    },

    getTipeSuratKeluar() {
      let self = this;
      self.loadingTable = true;
      axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "/api/master/tipe_surat_keluar?status=ENABLE"
        )
        .then((response) => {
          var response_data = response.data;
          if (response_data.code == 200) {
            self.tipe_surat_keluar = response_data.list_data.data;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data.message,
            });
          }
        });
    },

    getKategoriSurat() {
      let self = this;
      self.loadingTable = true;
      axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "/api/master/kategorisurat?status=ENABLE"
        )
        .then((response) => {
          var response_data = response.data;
          if (response_data.code == 200) {
            self.kategori_surat = response_data.list_data.data;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data.message,
            });
          }
        });
    },

    getTeamLeader() {
      let self = this;
      axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "/api/master/user/pic?departemen_id=" +
          self.departemen_selected?.id
        )
        .then((response) => {
          var response_data = response.data;
          if (response_data.code == 200) {
            self.team_leader = response_data.list_data.data;
          } else {
            //console.log('gagal get team leader');
          }
        });
    },

    uploadFile() {
      let self = this;
      if ($("#upload")[0].files[0]) {
        if ($("#upload")[0].files[0].size < 30000000) {
          $("#uploadLoading").html(
            '<span class="badge bg-warning p-1"><i class="fa fa-refresh fa-spin"></i> Loading...</span>'
          );
          var urlres = process.env.VUE_APP_BACKEND_URL_VERSION;

          var apiurl = process.env.VUE_APP_BACKEND_URL_VERSION + "api/arsip-dokumen/suratmasuk/uploadpdfwithqrcode";
          var FormData = require("form-data");
          var data = new FormData();
          data.append("file", $("#upload")[0].files[0]);
          data.append("q", '');
          if (self.ad_sifatsurat == 'biasa' || self.ad_sifatsurat == '') {
            data.append("e", '');
          } else {
            data.append("e", 'yes');
          }
          data.append("t", 'keluar');
          var config = {
            method: "post",
            url: apiurl,
            headers: {
              Accept: "application/json",
            },
            data: data,
          };
          axios(config).then(function (response) {
            var dir_name = response.data[0].file_upload.message.dir;
            urlres += dir_name;
            $("#uploadLoading").html(
              '<span class="badge bg-success p-1"><i class="fa fa-check"></i> Berhasil </span> <a href="' +
              urlres +
              '" target="_blank" style="padding-left:10px;"><span class="badge bg-success p-1"><i class="fa fa-eye"></i> Lihat File</span></a>'
            );
            self.ad_lampiran = dir_name;
            self.ad_lampiran_password = response.data[0].password;
          })
          .catch((e) => {
            $("#uploadLoading").html('<span></span>');
            Swal.fire({
              icon: "error",
              title: "Gagal upload file",
              text: e.response?.data?.message?.file,
            });
          });
        }else{
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "File Upload Lebih Dari 30Mb",
          });
        }
      }

    },

    // penugasan
    addlampiran() {
      this.lampiran.push({
        file: '',
        keterangan: '',
        kode: '',
      });
    },
    removelampiran(index) {
      this.lampiran.splice(index, 1);
    },

    uploadFileLampiran(row) {
      let self = this;
      if ($("#upload_file_lampiran"+row+"")[0].files[0]) {
        if ($("#upload_file_lampiran"+row+"")[0].files[0].size < 30000000) {
          $("#upload_loading_file_lampiran"+row+"").html(
            '<span class="badge bg-warning p-1"><i class="fa fa-refresh fa-spin"></i> Loading...</span>'
          );

          var urlres = process.env.VUE_APP_BACKEND_URL_VERSION;

          var apiurl = process.env.VUE_APP_BACKEND_URL_VERSION + "api/arsip-dokumen/suratmasuk/uploadpdfwithqrcode";
          var FormData = require("form-data");
          var data = new FormData();
          data.append("file", $("#upload_file_lampiran"+row+"")[0].files[0]);
          data.append("q", 'yes');
          if(self.ad_sifatsurat == 'biasa' || self.ad_sifatsurat == ''){
            data.append("e", '');
          }else{
            data.append("e", 'yes');
          }
          data.append("t", 'keluar');
          var config = {
            method: "post",
            url: apiurl,
            headers: {
              Accept: "application/json",
            },
            data: data,
          };
          axios(config).then(function (response) {
            //console.log(response);
            var dir_name = response.data[0].file_upload.message.dir;
            urlres += dir_name;
            $("#upload_loading_file_lampiran"+row+"").html(
              '<span class="badge bg-success p-1"><i class="fa fa-check"></i> Berhasil </span> <a href="' +
                urlres +
                '" target="_blank" style="padding-left:10px;"><span class="badge bg-success p-1"><i class="fa fa-eye"></i> Lihat File</span></a>'
            );

            self.lampiran[row].file = dir_name;
            self.lampiran[row].password = response.data[0].password;
          })
          .catch((e) => {
            $("#upload_loading_file_lampiran"+row+"").html('<span></span>');
            Swal.fire({
              icon: "error",
              title: "Gagal upload file",
              text: e.response?.data?.message?.file,
            });
          });
        }else{
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "File Upload Lebih Dari 30Mb",
          });
        }
      }
    },
    tanggalSuratChange() {
      let self = this;
      var tanggal_surat = $("#tanggalsurat").val().split('-');
      self.bulan_surat = tanggal_surat[1];
      self.tahun_surat = tanggal_surat[0];

      this.nomorSuratChange();
    },

    sifatSuratChange() {
      let self = this;
      if (self.ad_sifatsurat == "biasa") {
        self.sifat_surat_selected = "B";
      } else if (self.ad_sifatsurat == "rahasia") {
        self.sifat_surat_selected = "R";
      } else if (self.ad_sifatsurat == "sangat_rahasia") {
        self.sifat_surat_selected = "RS";
      }

      this.nomorSuratChange();
    },

    departemenChange() {
      let self = this;
      self.departemen_selected_code = self.departemen_selected?.code
      self.getTeamLeader();
      self.nomorSuratChange();
    },

    teamLeaderChange() {
      let self = this;
      self.team_leader_selected_nama = self.team_leader_selected?.name;
      self.nomorSuratChange();
    },

    tipeSuratKeluarChange() {
      let self = this;
      // self.bentuk_dokumen = [];
      self.tipe_surat_keluar_selected_kode = self.tipe_surat_keluar_selected?.kode;
      self.tipe_surat_keluar_selected_id = self.tipe_surat_keluar_selected?.id;
      //Is Direksi
      if (self.tipe_surat_keluar_selected_id != '2') {
        self.isKategoriSurat = false;
        self.isNoTelp = true;
        self.isTeamLeader = true;
        self.isDivisi = false;
        self.getDepartemen();

        // self.bentuk_dokumen.push(
        //   {
        //     key: 'surat',
        //     value: 'Surat',
        //   },
        //   {
        //     key: 'surat_dan_proposal',
        //     value: 'Surat dan Proposal',
        //   },
        //   {
        //     key: 'surat_dan_dokumen_pendukung_lainnya',
        //     value: 'Surat dan Pendukung Lainnya',
        //   },
        // );
      } else { //Is Divisi
        self.isKategoriSurat = true;
        self.isNoTelp = true;
        self.isTeamLeader = false
        self.isDivisi = true;
        self.getDepartemenByTipeSuratKeluar();

        //   self.bentuk_dokumen.push(
        //     {
        //       key: 'surat',
        //       value: 'Surat',
        //     },
        //   );
      }

      self.nomorSuratChange();
    },

    getDepartemenByTipeSuratKeluar() {
      let self = this;
      //GET DEPARTEMEN
      axios.get(process.env.VUE_APP_BACKEND_URL_VERSION + '/api/master/departemen?id=4').then((response) => {
        var response_data = response.data;
        if (response_data.code == 200) {
          self.departemen = response_data.list_data.data;
          self.departemen_selected = response_data.list_data.data[0];
        } else {
          //console.log('gagal get departemen by tipe surat keluar');
        }
      });
    },

    bookingSlotClick() {
      let self = this;
      self.draft = 'y';
      $("#submit-button").trigger('click');
    },

    simpanClick() {
      let self = this;
      self.draft = 'n';
      $("#submit-button").trigger('click');
    },

    nomorSuratChange() {
      let self = this;
      var sifatsurat = self.sifat_surat_selected;
      var tipe_surat_keluar = '';
      var divisi = self.ad_divisi;
      var bulan = self.bulan_surat;
      var tahun = self.tahun_surat;
      var departemen = '';
      var nama_team_leader = self.team_leader_selected_nama;
      var departemen_id = '';

      if (self.tipe_surat_keluar_selected_kode) {
        tipe_surat_keluar = self.tipe_surat_keluar_selected_kode;
      }
      if (self.departemen_selected_code) {
        departemen = self.departemen_selected_code;
        departemen_id = self.departemen_selected?.id;
      }
      if (self.departemen_selected?.id) {
        departemen_id = self.departemen_selected?.id;
      }

      axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "api/arsip-dokumen/suratkeluar/generateNoSuratByDepartment?departement_id=" +
          departemen_id +
          '&id_team_leader=' + nama_team_leader +
          '&sifat_surat=' + self.ad_sifatsurat +
          '&tipe_surat_keluar=' + tipe_surat_keluar +
          '&kodesifatsurat=' + self.sifat_surat_selected
        )
        .then((response) => {
          var response_data = response.data;
          self.no_surat = response_data.data;

        });

      if (self.tipe_surat_keluar_selected_id == '2') {
        self.ad_nomorsurat = sifatsurat + '.' + self.no_surat + '-' + tipe_surat_keluar + '/' + divisi + '/' + bulan + '/' + tahun;
      } else {
        self.ad_nomorsurat = sifatsurat + '.' + self.no_surat + '-' + tipe_surat_keluar + '/' + departemen + '/' + nama_team_leader + '/' + bulan + '/' + tahun;
      }
    },
    StoreEditNoSurat() {
      let self = this;
      self.nomorSuratChange();
      self.EditNoSuratModal = false;
    },

    StoreData() {
      let self = this;

      var kategori_surat_id = "";
      if (self.kategori_surat_selected?.id) {
        kategori_surat_id = self.kategori_surat_selected?.id;
      }

      var bentuk_dokumen_id = "";
      if (self.bentuk_dokumen_selected?.key) {
        bentuk_dokumen_id = self.bentuk_dokumen_selected?.key;
      }

      var departemen_id = "";
      if (self.role_slug != 'super_admin' && self.role_slug != 'sekretaris_divisi' && self.role_slug != 'kepala_divisi') {
        departemen_id = Cookies.get("session_departemen");
      }else{
        if (self.departemen_selected?.id) {
          departemen_id = self.departemen_selected?.id;
        }
      }

      var team_leader_id = "";
      if (self.team_leader_selected?.id) {
        team_leader_id = self.team_leader_selected?.id;
      }

      var kode_lamp = self.ad_lampiran_password
      if (kode_lamp) {
        kode_lamp = self.ad_lampiran_password
      } else {
        kode_lamp = '';
      }

      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
      });
      var FormData = require("form-data");
      var data = new FormData();
      data.append("ad_nomorsurat", self.ad_nomorsurat);
      data.append("ad_tsk_id", self.tipe_surat_keluar_selected_id);
      data.append("ad_tanggalsuratditerima", self.ad_tanggalsurat);
      data.append("ad_tanggalsurat", self.ad_tanggalsurat);
      data.append("ad_instansipengirim", self.ad_instansipengirim);
      data.append("ad_sifatsurat", self.ad_sifatsurat);
      data.append("ad_bentukdokumen", bentuk_dokumen_id);
      data.append("ad_departemen", departemen_id);
      data.append("ad_id_teamleader", team_leader_id);
      data.append("ad_divisi", self.ad_divisi);
      data.append("ad_kategorisurat_id", kategori_surat_id);
      data.append("ad_pic", self.ad_pic);
      data.append("ad_notelp", self.ad_notelp);
      data.append("ad_tandatangan", self.ad_tandatangan);
      data.append("ad_dikirim", self.ad_dikirim);
      data.append("ad_perihal", self.ad_perihal);
      data.append("ad_lampiran", self.ad_lampiran);
      data.append("ad_lampiran_password", kode_lamp);
      data.append("id_user_session", Cookies.get("session_id"));
      data.append("ad_tipesurat", "Surat Keluar");
      data.append("ad_duedate", '');
      data.append("lampiran_file", JSON.stringify(self.lampiran));

      var apiurl = '';
      if (self.draft == 'y') {
        apiurl = process.env.VUE_APP_BACKEND_URL_VERSION + "/api/arsip-dokumen/suratkeluar/store-draft";
      } else {
        apiurl = process.env.VUE_APP_BACKEND_URL_VERSION + "/api/arsip-dokumen/suratkeluar/store";
      }
      var config = {
        method: "post",
        url: apiurl,
        data: data,
      };
      axios(config)
        .then(function (response) {
          var response_data = response.data;
          //console.log(response);
          if (response_data.code != 200) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: response_data.message,
            });
          } else {
            let timerInterval;
            Swal.fire({
              icon: "success",
              title: "Berhasil",
              text: "Anda akan diarahkan ke halaman arsip dokumen surat keluar segera",
              timer: 2000,
              timerProgressBar: true,
              showCancelButton: false,
              showConfirmButton: false,
              willClose: () => {
                clearInterval(timerInterval);
              },
            }).then((result) => {
              /* Read more about handling dismissals below */
              if (result.dismiss === Swal.DismissReason.timer) {
                // self.$router.push({ name: "vendor" });
                Swal.close();
                if (self.draft == 'y') {
                  self.$router.push({ name: "surat_keluar_booking" });
                } else {
                  self.$router.push({ name: "all-surat_keluar" });
                }
              }
            });
          }
        })
        .catch((e) => {
          this.axiosCatchError = e.response.data.data;
          Swal.close();
        });
    },
    showNoSurat() {
      let self = this;
      if (self.ad_tanggalsurat && self.tipe_surat_keluar_selected) {
        self.EditNoSuratModal = false;
        self.loadingNoSurat = true;
        // config untuk re render component
        self.$nextTick(() => {
          self.EditNoSuratModal = true;
          self.loadingNoSurat = false;
          self.nomorSuratChange();
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          html: "Pastikan Data Terisi!",
        });
      }

    },
    bentukDokumenSelected() {
      let self = this;
      if (self.bentuk_dokumen_selected?.key != 'surat') {
        self.showLampiran = true;
      } else {
        self.showLampiran = false;
      }
    },
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <!-- <div class="row mb-2">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
        <ul class="list-group list-group-horizontal">
          <li class="col-md-6 list-group-item fs-5">
            <router-link :to="{ name: 'all-surat_keluar' }" class="text-dark">
              <i class="bx bx-mail-send"> </i> Surat Keluar
            </router-link>
          </li>
          <li class="col-md-6 list-group-item fs-5">
            <router-link :to="{ name: 'surat_keluar_booking' }" class="text-dark">
              <i class="bx bx-list-ol fs-5"> </i> Draft
              <span class="badge bg-primary rounded-pill">{{ total_draft }}</span>
            </router-link>
          </li>
        </ul>
      </div>
    </div> -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-form class="p-2" @submit.prevent="StoreData">
              <div class="row">
                <div class="col-md-6">
                  <b-form-group class="mb-3">
                    <div class="row">
                      <div class="col-md-6">
                        <label for="">Nomor Surat <span class="text-danger">* Wajib diisi</span></label>
                      </div>
                      <div class="col-md-6 text-end">
                        <div class="btn btn-info btn-sm" @click="showNoSurat">
                          <i class="fa fa-edit"></i> Edit
                        </div>
                      </div>
                    </div>

                    <input type="text" class="form-control" v-model="ad_nomorsurat" readonly />
                  </b-form-group>
                  <b-form-group class="mb-3">
                    <label>Tanggal Surat <span class="text-danger">* Wajib diisi</span></label>
                    <input type="date" v-model="ad_tanggalsurat" id="tanggalsurat" class="form-control"
                      @change="tanggalSuratChange" />
                  </b-form-group>
                  <b-form-group class="mb-3" label="Sifat Surat">
                    <select class="form-control input-sm" v-model="ad_sifatsurat" @change="sifatSuratChange">
                      <option value="">-Pilih Sifat Surat-</option>
                      <option value="biasa">Biasa</option>
                      <option value="rahasia">Rahasia</option>
                      <option value="sangat_rahasia">
                        Sangat Rahasia
                      </option>
                    </select>
                  </b-form-group>
                  <b-form-group class="mb-3">
                    <label>Surat Keluar <span class="text-danger">* Wajib diisi</span></label>
                    <v-select placeholder="-Pilih Tipe Surat Keluar-" :options="tipe_surat_keluar" label="nama"
                      v-model="tipe_surat_keluar_selected" @update:modelValue="tipeSuratKeluarChange"></v-select>
                  </b-form-group>
                  <b-form-group class="mb-3">
                    <label>Kategori Surat <span class="text-danger">* Wajib diisi</span></label>
                    <v-select placeholder="-Pilih Departemen-" :options="departemen" label="nama"
                      v-model="departemen_selected" @update:modelValue="departemenChange"></v-select>
                  </b-form-group>
                  <b-form-group class="mb-3" v-if="isDivisi">
                    <label>Divisi <span class="text-danger">* wajib diisi</span></label>
                    <input type="text" class="form-control" v-model="ad_divisi" v-on:keyup="nomorSuratChange()" />
                  </b-form-group>
                  <b-form-group class="mb-3">
                    <label>Bentuk Dokumen <span class="text-danger">* Wajib diisi</span></label>
                    <v-select placeholder="-Pilih Bentuk Dokumen-" :options="bentuk_dokumen" label="value"
                      v-model="bentuk_dokumen_selected" @update:modelValue="bentukDokumenSelected"></v-select>
                  </b-form-group>
                  <b-form-group class="mb-3" v-if="isTeamLeader">
                    <label>Team Leader <span class="text-danger">* wajib diisi</span></label>
                    <v-select placeholder="-Pilih Team Leader-" :options="team_leader" label="name"
                      v-model="team_leader_selected" @update:modelValue="teamLeaderChange"></v-select>
                  </b-form-group>
                  <b-form-group class="mb-3" v-if="isKategoriSurat">
                    <label for=""> Kategori Surat <span class="text-danger">* wajib diisi</span></label>
                    <v-select placeholder="-Pilih Kategori Surat-" :options="kategori_surat" label="mkt_kategori"
                      v-model="kategori_surat_selected"></v-select>
                  </b-form-group>
                </div>
                <div class="col-md-6">
                  <b-form-group class="mb-3" label="Instansi Penerima">
                    <b-form-input type="text" v-model="ad_instansipengirim"></b-form-input>
                  </b-form-group>
                  <b-form-group class="mb-3" label="Nama Penanggung Jawab">
                    <b-form-input type="text" v-model="ad_pic"></b-form-input>
                  </b-form-group>
                  <b-form-group class="mb-3" label="No Telp" v-if="isNoTelp">
                    <input type="text" maxlength="13" v-model="ad_notelp" class="form-control" onkeypress='return event.charCode >= 48 && event.charCode <= 57'>
                  </b-form-group>
                  <b-form-group class="mb-3" label="Dikirim Kepada">
                    <b-form-input type="text" v-model="ad_dikirim"></b-form-input>
                  </b-form-group>
                  <b-form-group class="mb-3" label="Nama Penanda Tangan">
                    <b-form-input type="text" v-model="ad_tandatangan"></b-form-input>
                  </b-form-group>
                  <input type="hidden" class="form-control" v-model="draft">
                </div>
              </div>
              <div class="row">
                <b-form-group class="mb-3" label="Perihal">
                  <textarea v-model="ad_perihal" id="" cols="30" rows="10" class="form-control"></textarea>
                </b-form-group>
                <b-form-group class="mb-3" label="Attachment">
                  <input type="file" class="form-control" id="upload" @change="uploadFile" accept="application/pdf" />
                  <input type="hidden" v-model="ad_lampiran">
                  <div class="respond-input-file float-left" id="uploadLoading"></div>
                  <p class="help-block" style="color: red">
                    File yang diinputkan harus .pdf dan Max 30MB
                  </p>
                </b-form-group>
              </div>

              <div class="row" v-if="showLampiran">
                <div class="col-md-12">
                  <table class="table mb-0 table-bordered table-condensed table-hover w-full mb-2">
                    <thead>
                      <tr class="bg-light">
                        <th colspan="4">Lampiran</th>
                      </tr>
                      <tr class="bg-light">
                        <th>#</th>
                        <th>File</th>
                        <th>Keterangan</th>
                        <th class="text-end">
                          <div class="btn btn-success btn-sm" v-on:click="addlampiran()">
                            <i class="bx bx-plus"></i> Tambah Lampiran
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="lampiran.length == 0">
                        <td colspan="4">TIdak Ada Data</td>
                      </tr>
                      <tr v-for="(item, index) in lampiran" :key="index">
                        <td>#</td>
                        <td>
                          <input type="file" class="form-control" :id="'upload_file_lampiran' + index"
                            @change="uploadFileLampiran(index)" accept="application/pdf">
                          <input type="hidden" v-model="item.file">
                          <div class="respond-input-file float-left" :id="'upload_loading_file_lampiran' + index"></div>
                        </td>
                        <td>
                          <input type="text" class="form-control" v-model="item.keterangan" />
                          <input type="hidden" class="form-control" v-model="item.kode" />
                        </td>
                        <td class="text-center">
                          <button type="button" class="btn btn-danger btn-sm" @click="removelampiran(index)">
                            <i class="fa fa-minus"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="text-end">
                    <div class="btn btn-info m-1" v-if="tipe=='booking'" v-on:click="bookingSlotClick">
                      <i class="fa fa-book"></i> Booking Slot
                    </div>
                    <div class="btn btn-primary m-1" v-if="tipe=='surat_keluar'" v-on:click="simpanClick">
                      <i class="fa fa-save"></i> Simpan
                    </div>
                  </div>
                </div>
              </div>

              <button type="submit" style="display:none" id="submit-button">Submit</button>
            </b-form>
          </div>
        </div>
      </div>
    </div>

  </Layout>
  <b-modal v-model="EditNoSuratModal" title="Edit No Surat" title-class="text-black font-18" body-class="p-3" hide-footer>
    <div class="row">
      <form @submit.prevent="StoreEditNoSurat">
        <div class="col-md-12">
          <div v-if="loadingNoSurat">
            <i class="fas fa-spinner fa-spin"></i> Loading...
          </div>
          <div v-else>
            <div v-if="tipe_surat_keluar_selected_id == '2'">
              <div class="input-group mb-3">
                <span class="input-group-text">{{ sifat_surat_selected }}.{{ no_surat }}</span>
                -<input type="text" class="form-control" v-model="tipe_surat_keluar_selected_kode">./<input type="text" class="form-control" v-model="ad_divisi">/<span class="input-group-text">{{ bulan_surat
                }}/{{ tahun_surat }}</span>
              </div>
            </div>
            <div v-else>
              <div class="input-group mb-3">
                <span class="input-group-text">{{ sifat_surat_selected }}.{{ no_surat }}</span>
                -<input type="text" class="form-control" v-model="tipe_surat_keluar_selected_kode">/<input type="text" class="form-control" v-model="departemen_selected_code">/
                  <input type="text" class="form-control" v-model="team_leader_selected_nama"><span class="input-group-text">/{{ bulan_surat }}/{{ tahun_surat }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="text-end pt-5 mt-3">
          <b-button type="submit" variant="primary" class="ms-1"><i class="fa fa-save"></i> Simpan</b-button>
        </div>
      </form>
    </div>
  </b-modal>
</template>
